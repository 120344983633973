/*
-------------------------------- 
TYPOGRAPHY
--------------------------------
*/
:root {
    --font-content: "content", "helvetica", "arial", sans-serif;
    --font-title: "title", serif;

    /* set base values */
    --text-base-size: 1em;
    --text-scale-ratio: 1.25;

    /* type scale */
    --text-xs: calc(1em / (var(--text-scale-ratio) * var(--text-scale-ratio)));
    --text-sm: calc(1em / var(--text-scale-ratio));
    --text-md: calc(1em * var(--text-scale-ratio));
    --text-lg: calc(1em * var(--text-scale-ratio) * var(--text-scale-ratio));
    --text-xl: calc(1em * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio));
    --text-xxl: calc(
        1em * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio)
    );
    --text-xxxl: calc(
        1em * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) *
            var(--text-scale-ratio)
    );

    /* line-height */
    --heading-line-height: 1.2;
    --body-line-height: 1.4;
}

.text--xxxl {
    font-size: var(--text-xxxl);
}

h1,
.text--xxl {
    font-size: var(--text-xl);

    @include breakpoint(sm) {
        font-size: var(--text-xxl);
    }
}

h2,
.text--xl {
    font-size: var(--text-xl);
}

h3,
.text--lg {
    font-size: var(--text-lg);
}

h4,
.text--md {
    font-size: var(--text-md);
}

.text--sm,
small {
    font-size: var(--text-sm);
}

.text--xs {
    font-size: var(--text-xs);
}

p {
    line-height: var(--body-line-height);
}

legend {
    font-size: var(--text-sm);
    text-transform: uppercase;
    opacity: 0.64;
}

a{
  cursor:pointer;
  letter-spacing: -0.5px;
}
/*
-------------------------------- 
RESPONSIVE
--------------------------------
*/
@include breakpoint(xl) {
    :root {
        --text-base-size: 1.125em;
    }
}
