/*
-------------------------------- 
HEADER LAYOUT
--------------------------------
*/
header {
   position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    padding: var(--space-sm) 0;
    z-index: $header_level;

    @include breakpoint(sm) {
        padding: var(--space-md) 0;
    }

    &:before {
        content: "";
        @include size(100%, 100%);
        @include absolute();
        background: var(--primary_grey);
        transform: translateY(-100%);
        transition: transform 0.8s $easeCustom;
    }

    .header-logo {
        position: relative;

        svg {
            width: auto;
            height: 16px;

            path {
                fill: var(--secondary);
                transition: fill 0.4s ease;
            }
        }
    }

    /*
    -------------------------------- 
    Main Header nav
    --------------------------------
    */
    .header-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: color 0.24s ease;

        #header-nav {
            display: none;

            ul {
                display: flex;

                li {
                    &:not(:last-child) {
                        margin-right: var(--space-md);
                    }
                }
            }

            @include breakpoint(sm) {
                display: flex;
            }

            /*
            -------------------------------- 
            Language Selector
            --------------------------------
            */
            .language-selector {
                margin-left: var(--space-sm);

                .language-item {
                    text-transform: uppercase;

                    &:first-child {
                        margin-right: var(--space-xs);
                    }

                    &.active {
                        opacity: 0.4;
                    }
                }
            }
        }
    }

    /*
    -------------------------------- 
    Burger
    --------------------------------
    */
    .burger {
        position: relative;
        cursor: pointer;
        padding: 12px 0 12px 12px;
        display: block;

        div {
            @include size(32px, 1px);
            background-color: var(--secondary);
            display: block;
            position: relative;
            margin-bottom: 3px;
            transition: transform 0.64s $easeOutExpo, opacity 0.24s $easeOutExpo, background-color 0.24s ease;
        }

        @include breakpoint(sm) {
            display: none;
        }
    }

    /*
    -------------------------------- 
    Light
    --------------------------------
    */
    &.is-light {
        .header-logo {
            svg {
                path {
                    fill: var(--fixed-white);
                }
            }
        }

        .header-container {
            color: var(--fixed-white);

            a {
                &:after {
                    background: var(--fixed-white);
                }
            }
        }

        .burger {
            div {
                background-color: var(--fixed-white);
            }
        }
    }

    /*
    -------------------------------- 
    On scroll
    --------------------------------
    */
    &.is-scrolled {
        .header-logo {
            svg {
                path {
                    fill: var(--secondary);
                }
            }
        }

        .header-container {
            color: var(--secondary);
        }

        &:before {
            transform: translateY(0%);
        }
    }
}

/*
-------------------------------- 
Nav Fullscreen
--------------------------------
*/
#nav-fullscreen {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: var(--fixed-black);
    z-index: $navFullscreen_level;
    text-transform: uppercase;
    color: var(--fixed-white);
    display: block;
    opacity: 0;
    pointer-events: none;

    nav {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;

        ul {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            li {
                text-transform: uppercase;
                font-size: var(--text-lg);

                &:not(:first-child) {
                    margin-top: var(--space-xs);
                }
            }
        }

        .language-selector {
            margin-top: var(--space-lg);

            .language-item {
                text-transform: uppercase;
                font-size: var(--text-md);

                &:first-child {
                    margin-right: var(--space-xs);
                }

                &.active {
                    opacity: 0.4;
                }
            }
        }
    }

    @include breakpoint(sm) {
        display: none;
    }
}

body {
    &.home {
        header {
            .header-logo {
                user-select: none;
                pointer-events: none;
            }
        }
    }
}

/*
-------------------------------- 
Body is Menu
--------------------------------
*/
.is-menu {
    header {
        .header-logo {
            svg {
                path {
                    fill: var(--fixed-white);
                }
            }
        }

        .burger {
            div {
                background-color: var(--fixed-white);

                &:nth-child(2) {
                    opacity: 0;
                }

                &:first-child {
                    transform: translate3d(0, 4px, 0) rotate3d(0, 0, 1, -45deg);
                }
                &:last-child {
                    transform: translate3d(0, -4px, 0) rotate3d(0, 0, 1, 45deg);
                }
            }
        }
    }

    #nav-fullscreen {
        opacity: 1;
        pointer-events: auto;
    }
}
