/*
-------------------------------- 
HERO COMPONENT
--------------------------------
*/
.hero-section {
    &.hero-fullscreen {
        position: relative;
        @include size(100%, calc(100vh + 1px));

        .bg-container {
            @include size(100%, 100%);
            @include absolute();
            z-index: initial;

            .img-hero {
                height: 100%;
                width: 100%;

                img {
                    height: 100%;
                    object-fit: cover;
                }
            }

            .video-hero {
                height: 100%;
                width: 100%;
                background: var(--secondary);

                video {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: unset;
                    min-width: 100%;
                    min-height: 100%;
                }
            }
        }

        .hero-content {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 3;
            @include size(100%, 100%);
        }
    }

    .hero-scrollto {
        position: absolute;
        bottom: 0;
    }

    &.hero-white {
        color: var(--fixed-white);
    }
}
