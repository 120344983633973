/*
-------------------------------- 
PARTIAL LAYOUT
--------------------------------
*/
.current-menu-item {
    pointer-events: none;

    a {
        opacity: 0.64;
    }
}

