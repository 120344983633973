body {
  background-color: var(--couleur-site);
}

/*///////////////////////////// header /////////////////////////////*/
/*///////////////////////////// footer /////////////////////////////*/
/*///////////////////////////// SVG conteneur /////////////////////////////*/
.gallery {
  display: grid;
  grid-template-rows: 80vh 20vh;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  width: 100%;
  opacity: 0;
}

.sous-gallery {
  opacity: 0;
  width: 100vw;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
}

.logo_svg {
  width: 60%;
  margin: auto;
  padding-top: 2vh;
}

#modele1:hover,
#modele3:hover,
#modele5:hover,
#modele7:hover {
  position: relative;
  width: 8%;
  filter: none;
}

#modele2:hover,
#modele4:hover,
#modele6:hover {
  position: relative;
  width: 8%;
  filter: none;
}

#modele1,
#modele2,
#modele3,
#modele4,
#modele5,
#modele6,
#modele7 {
  position: relative;
  width: 8%;
  transition: all 0.3s ease;
}

.grayscale {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: all 200ms ease-in-out;
}

.grayscale:hover {
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  filter: grayscale(0);
}

/*///////////////////////////// SVG modifiable /////////////////////////////*/
.contour {
  color: var(--couleur-default-contour-svg);
  cursor: pointer;
}

.contour.clicked {
  color: var(--couleur-contour);
}

.centre {
  color: var(--couleur-default-svg);
  cursor: pointer;
}

.centre.clicked {
  color: var(--couleur-centre);
}

.p1,
.p2,
.p3,
.p4,
.p5,
.p6,
.p7 {
  color: var(--couleur-default-svg);
  cursor: pointer;
}

.p1.clicked,
.p2.clicked,
.p3.clicked,
.p4.clicked,
.p5.clicked,
.p6.clicked,
.p7.clicked {
  color: var(--couleur-petales);
}

.pt1,
.pt2,
.pt3,
.pt4,
.pt5,
.pt6,
.pt7 {
  color: var(--couleur-default-svg);
  cursor: pointer;
}

.pt1.clicked,
.pt2.clicked,
.pt3.clicked,
.pt4.clicked,
.pt5.clicked,
.pt6.clicked,
.pt7.clicked {
  color: var(--couleur-petites-petales);
}

/*///////////////////////////// SVG modele /////////////////////////////*/
.contour-modele {
  color: var(--couleur-default-contour-svg);
  cursor: pointer;
}

.contour-modele.clicked {
  color: var(--couleur-contour);
  cursor: pointer;
}

.centre-modele {
  color: white;
  cursor: pointer;
}

.centre-modele.clicked {
  color: var(--couleur-centre);
  cursor: pointer;
}

.p1-modele,
.p2-modele,
.p3-modele,
.p4-modele,
.p5-modele,
.p6-modele,
.p7-modele {
  color: var(--couleur-default-svg);
  cursor: pointer;
}

.p1-modele.clicked,
.p2-modele.clicked,
.p3-modele.clicked,
.p4-modele.clicked,
.p5-modele.clicked,
.p6-modele.clicked,
.p7-modele.clicked {
  color: var(--couleur-petales);
  cursor: pointer;
}

.pt1-modele,
.pt2-modele,
.pt3-modele,
.pt4-modele,
.pt5-modele,
.pt6-modele,
.pt7-modele {
  color: var(--couleur-default-svg);
  cursor: pointer;
}

.pt1-modele.clicked,
.pt2-modele.clicked,
.pt3-modele.clicked,
.pt4-modele.clicked,
.pt5-modele.clicked,
.pt6-modele.clicked,
.pt7-modele.clicked {
  color: var(--couleur-petites-petales);
}

/*////////////////////////////////////// about /////////////////////////////////////////////////////////*/
.contact-grid {
  width: 100%;
  visibility: hidden;
  height: 100vh;
  padding: 45px 36vw;
  position: fixed;
  z-index: 9999999999;
  display: grid;
  align-content: space-between;
  justify-content: space-around;
}

.split {
  padding: 20px 0px;
}

.split-p {
  overflow: hidden;

}

/*///////////////////////////////////////////////////////////////////////////////////////////////*/

.split.bottom .split-c {
  text-align: left;
}

.split.right .split-c {
  text-align: left;
}

/*///////////////////////////////////////////////////////////////////////////////////////////////*/
@media screen and (max-width: 640px) {
  .logo_svg {
    width: 100%;
    transform: scale(1.7);
  }

  body {
    overflow: hidden;
  }

  .contact-grid {
    position: fixed;
    height: 90vh;
    padding: 45px 4vw;
  }

  .gallery {
    position: fixed;
    grid-template-rows: 75vh 20vh;
  }

  #modele1:hover,
  #modele3:hover,
  #modele5:hover,
  #modele7:hover {
    width: 25%;
  }

  #modele2:hover,
  #modele4:hover,
  #modele6:hover {
    width: 25%;
  }

  #modele1,
  #modele2,
  #modele3,
  #modele4,
  #modele5,
  #modele6,
  #modele7 {
    width: 25%;
  }
}