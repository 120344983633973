/*
-------------------------------- 
BUTTON COMPONENT
--------------------------------
*/
.js-element {
	display: inline-block;
}

.underline,
#breadcrumbs a {
	position: relative;
	color: inherit;
	cursor: pointer;
}

.is-desktop {
	.underline,
	#breadcrumbs a {
		&:after {
			content: "";
			position: absolute;
			height: 1px;
			width: 100%;
			bottom: 0;
			left: 0;
			background: var(--secondary_grey);
			transform: scaleX(0);
			transform-origin: right;
			transition: transform 1.2s $easeSmooth;
		}

		&:hover {
			&:after {
				transform: scaleX(1);
				transform-origin: left;
			}
		}

		&.is-white {
			&:after {
				background: var(--fixed-white);
			}
		}
	}
}
