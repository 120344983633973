/*
-------------------------------- 
CONTACT PAGE
--------------------------------
*/
#map {
    width: 100%;
    background: var(--secondary);
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
}
