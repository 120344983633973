/*
-------------------------------- 
COLORS
--------------------------------
*/
:root {
    --primary: #fff;
    --secondary: #000;

    --fixed-white: #fff;
    --fixed-black: #000;

    --primary_grey: #f7f7f4;
    --half_grey: #5f5f5f;
    --secondary_grey: #1f1f1f;

    --brand_primary: #eb493e;
    --brand_secondary: #073f34;

    --support: mistyrose;
}

[data-theme="dark"]{
    --primary: #000;
    --secondary: #fff;

    --primary_grey: #1f1f1f;
    --secondary_grey: #f7f7f4;

    --support: #505064;
}