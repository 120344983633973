/*
-------------------------------- 
FOOTER LAYOUT
--------------------------------
*/
footer {
    padding: var(--space-md) 0;

    .footer-container {
        display: flex;

        .app-copyright {
            margin-right: var(--space-sm);
        }
    }
}
