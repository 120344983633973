/*
-------------------------------- 
LOCOMOTIVE SCROLL
--------------------------------
*/
html {
    &.has-scroll-smooth {
        overflow: hidden;
    }

    &.has-scroll-dragging {
        user-select: none;
    }
}

body {
    .has-scroll-smooth & {
        overflow: hidden;
    }
}

[data-scroll-container] {
    will-change: transform;

    .has-scroll-smooth & {
        min-height: 100vh;
    }
    [data-scroll-direction="horizontal"] & {
        white-space: nowrap;
        height: 100vh;
        display: inline-block;
        white-space: nowrap;
    }
}

[data-scroll-section] {
    [data-scroll-direction="horizontal"] & {
        display: inline-block;
        vertical-align: top;
        white-space: nowrap;
        height: 100%;
    }
}

/*
-------------------------------- 
Scrollbar
--------------------------------
*/
.c-scrollbar {
    position: absolute;
    right: 0;
    top: 0;
    width: 11px;
    height: 100%;
    transform-origin: center right;
    transition: transform 0.3s, opacity 0.3s;
    opacity: 0;

    &:hover {
        transform: scaleX(1.45);
    }

    &:hover,
    .has-scroll-scrolling &,
    .has-scroll-dragging & {
        opacity: 1;
    }

    [data-scroll-direction="horizontal"] & {
        width: 100%;
        height: 10px;
        top: auto;
        bottom: 0;
        transform: scaleY(1);

        &:hover {
            transform: scaleY(1.3);
        }
    }

    .c-scrollbar_thumb {
        position: absolute;
        top: 0;
        right: 0;
        background-color: black;
        width: 7px;
        border-radius: 10px;
        margin: 2px;
        cursor: grab;
        transition: opacity .4s ease;
        opacity: 0.5;

        .has-scroll-dragging & {
            cursor: grabbing;
        }

        [data-scroll-direction="horizontal"] & {
            right: auto;
            bottom: 0;
        }
    }

    &.is-off{
        .c-scrollbar_thumb {
            opacity: 0;
        }
    }
}
