/*
-------------------------------- 
FORMS COMPONENT
--------------------------------
*/

// Default
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

input,
input[type="search"],
input[type="text"],
input[type="email"],
textarea {
  /* Remove First */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* Then Style */
  border-radius: 0px;
  border: none;
  background: transparent;
  outline: none;
  color: inherit;

  &:focus, &:active{
    color: inherit;
  }
}

textarea {
  resize: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

button,
input[type="submit"] {
  cursor: pointer;
  background: none;
}

// Style
input[type="search"],
input[type="text"],
input[type="email"] {
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.4);
}

[data-theme="dark"] {
  // Style
  input[type="search"],
  input[type="text"],
  input[type="email"] {
    border-bottom: 1px solid rgba($color: #ffffff, $alpha: 0.4);
  }
}
