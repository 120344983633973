/*
-------------------------------- 
THEME GLOBAL
--------------------------------
*/
@import "base/fonts";
@import "base/default";

// Layout
@import "layout/header";
@import "layout/footer";
@import "layout/partial";

// Pages
@import "pages/home";
@import "pages/single";
@import "pages/about";
@import "pages/contact";

// Components
@import "components/partial";
@import "components/buttons";
@import "components/preloader";
@import "components/form";
@import "components/cookies";
@import "components/lazyload";
@import "components/hero";

// Delete this
@import "replace";
