/*
-------------------------------- 
LAZYLOAD COMPONENT
--------------------------------
*/
.lazy-container {
    position: relative;
    overflow: hidden;

    .placeholder {
        @include absolute();
        @include size(100%, 100%);
        background: var(--support);
        transition: opacity .5s ease;
        transition-delay: .3s;
        pointer-events: none;
        user-select: none;
        z-index: 2;
    }

    .lazy{
        z-index: initial;
    }

    &.is-load {
        .placeholder {
            opacity: 0;
        }
    }
}
